import React, { useContext, useEffect, useCallback, useState } from "react"
import { navigate } from "gatsby"
import { QuoteContext } from "../Context/QuoteContext"
import { loadQuote, toggleHasFetchedQuote } from "../Context/Actions"
import { isGuid, isNullOrWhitespace } from "../utils/HelperFunctions"
import QuoteLandingPage from "../components/PageComponents/QuoteLandingPage/QuoteLandingPage"
import Loader from "../components/GlobalAssets/Loader"

const CompletePurchasePage = ({ location }) => {
  const { state, dispatch } = useContext(QuoteContext)
  const [invalidGuid, setInvalidGuid] = useState(false)
  const params = new URLSearchParams(location.search)

  const guid = params.get("g")
  const e = params.get("e")
  const paymentid = params.get("paymentid")

  const stableDispatch = useCallback(dispatch, [])

  useEffect(() => {
    if (isNullOrWhitespace(guid) || !isGuid(guid)) {
      setInvalidGuid(true)
      return
    }

    stableDispatch(toggleHasFetchedQuote())

    if (!isNullOrWhitespace(paymentid)) {
      stableDispatch(loadQuote({ guid: `${guid}/${paymentid}` }))
      return
    }

    if (!isNullOrWhitespace(e)) {
      stableDispatch(loadQuote({ guid: `${guid}/${e}` }))
      return
    }

    stableDispatch(loadQuote({ guid: guid }))
  }, [stableDispatch, guid, e, paymentid])

  useEffect(() => {
    if (state.hasLoadedQuote && !state.loadedQuoteFailed.failed) {
      const queryString = new URLSearchParams(location.search).toString()

      switch (state.quote.result.quoteStatus) {
        case "new":
          if (state.quote.result.insType === "Home") {
            navigate(`/forsakringar/hem/lagg-till-mobilnummer?${queryString}`)
            break
          }

          navigate(`/bilforsakring/lagg-till-mobilnummer?${queryString}`)

          break
        case "pending":
          if (state.quote.result.insType === "Product") {
            navigate(`/forsakring/bekraftelse?${queryString}`)
            break
          }

          if (state.quote.result.insType === "Home") {
            navigate(`/forsakringar/hem/verifiera-pinkod?${queryString}`)
            break
          }

          navigate(`/bilforsakring/verifiera-pinkod?${queryString}`)

          break
        case "verified":
          if (state.quote.result.insType === "Home") {
            navigate(`/forsakringar/hem/betala?${queryString}`)
            break
          }

          navigate(`/bilforsakring/betala?${queryString}`)
          break
        case "active":
          if (state.quote.result.successUrl) {
            navigate(state.quote.result.successUrl, {
              referrerPolicy: "no-referrer",
              replace: true,
            })
            break
          }

          if (state.quote.result.insType === "Product") {
            navigate(`/forsakring/bekraftelse?${queryString}`)
            break
          }

          if (state.quote.result.insType === "Home") {
            navigate(`/forsakringar/hem/bekraftelse?${queryString}`)
            break
          }

          navigate(`/bilforsakring/bekraftelse?${queryString}`)

          break
        case "renew":
          navigate(`/service/change-payment?${queryString}`)

          break
        case "renew_success":
          navigate(`/service/change-payment-complete?${queryString}`)
          break
        default:
          navigate(`/404`)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loadedQuote])

  if (state.loadedQuoteFailed.failed || invalidGuid) {
    return <QuoteLandingPage invalidGuid={invalidGuid} />
  }

  return <Loader />
}

export default CompletePurchasePage
